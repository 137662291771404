@import 'variable';
@import 'mixin';

/* ======================================================================
 p_404
====================================================================== */

.l_main_visual {
	background-image: url(../img/p_404/hd_bg.jpg);
  @include sp {
    background-image: url(../img/p_404/hd_bg_sp.jpg);
  }
}

.sec01 {
  position: relative;
  width: 100%;
  padding: 74px 80px;
  .inner {
    width: 630px;
    margin: 0 auto;
  }
  .ttl {
    text-align: center;
    font-size: 60px;
    line-height: 42px;
    font-family: $font_2;
    font-weight: bold;
    color: $color_4;
    margin: 0 0 40px;
    .accent {
      font-size: 36px;
      display: block;
      margin-top: 6px;
    }
  }
  .lead {
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
  }
  .list {
    margin-top: 30px;
    background-color: #F2F2F2;
    border-radius: 6px;
    padding: 30px;
    .item {
      font-size: 16px;
      line-height: 26px;
      &:nth-of-type(n+2) {
        margin-top: 10px;
      }
    }
  }
  .p {
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    margin-top: 30px;
  }
  .back_btn {
    margin-top: 70px;
  }

  
  @include sp {
    padding: 50px 0;
    .inner {
      width: 100%;
      padding: 0 15px;
    }
    .ttl {
      margin: 0 0 30px;
    }
    .lead {
      text-align: left;
    }
    .list {
      margin-top: 20px;
      padding: 20px;
      .item {
        &:nth-of-type(n+2) {
        }
      }
    }
    .p {
      text-align: left;
      margin-top: 20px;
    }
    .back_btn {
      margin-top: 40px;
    }
  }
}
